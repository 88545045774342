export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.client) {
    const router = useRouter()
    try {
      const token = localStorage.getItem('authToken')
      if (!token) {
        router.push('/login')
      }
    }
    catch (e) {
      console.error((e as Error) as Error)
      router.push('/error')
    }
  }
})
